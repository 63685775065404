<template>
  <c-box
    position="relative"
    z-index="3"
    margin-block="12px"
    :border-radius="['12px', '0px']"
    background-color="#FFF"
    :box-shadow="['4px 4px 10px rgba(0, 0, 0, 0.15)', 'none']"
  >
    <!-- Program dan Layanan Dietela -->
    <c-flex
      width="100%"
      max-width="1100px"
      justify-content="center"
      align-items="center"
      mx="auto"
    >
      <c-flex
        flex-direction="column"
        width="100%"
        justify-content="center"
        align-content="center"
        :px="['16px', '24px']"
        py="12px"
        pos="relative"
      >
        <c-grid
          v-if="listMenu.length > 0"
          width="100%"
          :template-columns="['repeat(3, 1fr)' ,`repeat(${listMenu.length > 6 ? 6 : listMenu.length}, 1fr)`]"
          mx="auto"
          padding-inline="8px"
          :gap="['12px', '12px']"
        >
          <c-box
            v-for="(menu, index) in listMenu"
            :key="index"
          >
            <c-button
              v-if="menu?.route"
              as="router-link"
              :to="menu?.route"
              :target="menu?.isNewTab ? '_blank' : ''"
              :href="menu?.href"
              display="flex"
              width="100%"
              :height="['92px', '108px']"
              :min-height="['unset', '108px']"
              :max-width="['100%', '200px']"
              mx="auto"
              flex-direction="column"
              jusity-content="center"
              align-items="center"
              border="none"
              :border-radius="['12px', '12px']"
              :padding="['8px', '8px']"
              cursor="pointer"
              color="#111111"
              background-color="#FFF"
              white-space="pre-warp"
              :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)']"
            >
              <c-image
                :src="menu.icon"
                :height="['35px', '60px']"
                width="auto"
              />
              <c-text
                :mt="['8px', '8px']"
                :font-size="['12px', '18px']"
                text-align="center"
                :font-weight="['400', '500']"
                font-family="Roboto"
              >
                {{ menu.textMenu }}
              </c-text>
            </c-button>
            <c-button
              v-else
              as="a"
              :target="menu?.isNewTab ? '_blank' : ''"
              :href="menu?.href"
              display="flex"
              width="100%"
              :height="['92px', '108px']"
              :min-height="['unset', '108px']"
              :max-width="['100%', '200px']"
              mx="auto"
              flex-direction="column"
              jusity-content="center"
              align-items="center"
              border="none"
              :border-radius="['12px', '12px']"
              :padding="['8px', '8px']"
              cursor="pointer"
              color="#111111"
              background-color="#FFF"
              white-space="pre-warp"
              :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)']"
            >
              <c-image
                :src="menu.icon"
                :height="['35px', '60px']"
                width="auto"
              />
              <c-text
                :mt="['8px', '8px']"
                :font-size="['12px', '18px']"
                text-align="center"
                :font-weight="['400', '500']"
                font-family="Roboto"
              >
                {{ menu.textMenu }}
              </c-text>
            </c-button>
          </c-box>
        </c-grid>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { LINK_DIETELA_SHOP } from '@/constants/generals'

export default {
  name: 'MainMenu',
  computed: {
    listMenu() {
      return [
        {
          id: 1,
          textMenu: 'Program',
          route: { name: 'client.select-program' },
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/program_menu.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676450338677',
          isNewTab: false,
        },
        {
          id: 2,
          textMenu: 'Panduan Makan',
          route: { name: 'under-maintenance' },
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/mealplan_menu.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676450338499',
          isNewTab: false,
        },
        {
          id: 3,
          textMenu: 'Nilai Gizi',
          route: { name: 'client.glossaries' },
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/glossary_menu.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676450338672',
          isNewTab: false,
        },
        {
          id: 4,
          textMenu: 'Pilih Ahli Gizi',
          route: { name: 'client.nutritionists' },
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/nutritionist_menu_2_.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676453456144',
          isNewTab: false,
        },
        {
          id: 5,
          textMenu: 'Toko',
          href: this.LINK_DIETELA_SHOP,
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/refactor_landing_page/shop_10374480%201.webp?updatedAt=1714983825217',
          isNewTab: this.$isMobile() ? false : true,
        },
        {
          id: 6,
          textMenu: 'AjakSehat',
          route: { name: 'client.profile.referral' },
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/refactor_landing_page/teamwork_13450349%201.webp?updatedAt=1714983825293',
          isNewTab: false,
        },
      ]
    },
    LINK_DIETELA_SHOP() {
      return LINK_DIETELA_SHOP
    },
  },
}
</script>
